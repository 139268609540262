import i18next from '@theme/translations'

const siteConfig = {
  winery: 'bond',
  metadata: {
    title: `BOND`,
    description: `A portfolio of wines that are diverse in their geographic representation and ‘Grand Cru’ in quality, all under the umbrella of one philosophy, one team, one mark.`,
    phone: '(707) 944-9445',
    wines: 'BOND and Matriarch',
  },
  loginPath: '/login',
  loginTitle: 'Login',
  signupPath: '/allocation',
  signupTitle: 'Request Allocation',
  countOfInHouseInventoryLocations: 2,
  director: {
    name: 'Max Kast, MS',
    nameWithTitle: 'Max Kast, MS, Director of BOND,',
    email: 'kast@bond.wine',
  },
  patronRelations: {
    name: 'Claire Gabaldon',
    email: 'claire@bond.wine',
    hospitalityEmail: 'molly@bond.wine',
    hospitalityPhone: '(707) 944-9445',
  },
  isFlacActive: false,
  nextVintageWines: '2023 BOND and the 2022 vintage of Matriarch',
  lastVintageWines: '2022 BOND or the 2021 vintage of Matriarch',
  settings: {
    skipLetterDestination: '/allocation',
    useCustomerLetters: false,
  },
}

if (process.env.GATSBY_SENTRY_ENV == 'production') {
  siteConfig.ccWorkaroundInventoryLocationId = '118cd1e9-018f-4b20-b29f-53e42fca50f0' // "bond-production", BOND Warehouse
} else {
  siteConfig.ccWorkaroundInventoryLocationId = '216a480a-a7ea-42d4-92f6-ece20562dab3' // "bond" instance, BOND Warehouse
}

export default siteConfig
